import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CircleUser,
  Flame,
  Home,
  LayoutDashboard,
  Menu,
  Package,
  Search,
  ShoppingCart,
  Users,
} from "lucide-react";
import { Badge } from "../components/ui/badge"
import { Button } from "../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Input } from "../components/ui/input";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import { deleteCookie } from "../misc/utils";
import PAKKmaxLogo from "./PAKKmaxLogo";

export const Header = ({...props }) => {
  const location = useLocation();
  
  const handleLogout = () => {
    deleteCookie("access_token");
    localStorage.clear();
  };

  // Function to determine if a link is active
  const isActive = (path) => (location.pathname).includes(path);

  return (
    <header className="fixed top-0 left-0 right-0 z-10 flex h-14 items-center gap-4 border-b bg-muted px-4 lg:h-[60px] lg:px-6 justify-between lg:justify-end md:justify-end">
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="shrink-0 md:hidden"
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="flex flex-col">
          <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
            <PAKKmaxLogo />
          </div>
          <nav className="grid gap-2 text-lg font-medium">
          <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`dashboard`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <LayoutDashboard className="h-4 w-4" />
              Dashboard
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/orders`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`orders`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <ShoppingCart className="h-4 w-4" />
              Orders
              <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                6
              </Badge>
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/products`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`products`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Package className="h-4 w-4" />
              Products
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/deals`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`deals`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Flame className="h-4 w-4" />
              Deals
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/team`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`team`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Users className="h-4 w-4" />
              Team
            </Link>
          </nav>
        </SheetContent>
      </Sheet>
      {/* <div className="w-full flex-1">
        <form>
          <div className="relative">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search products..."
              className="w-full appearance-none bg-background pl-8 shadow-none md:w-2/3 lg:w-1/3"
            />
          </div>
        </form>
      </div> */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="icon" className="rounded-full">
            <CircleUser className="h-5 w-5" />
            <span className="sr-only">Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem>Settings</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <a href={`${process.env.PUBLIC_URL}/login`} onClick={handleLogout}>
              <span>Logout</span>
            </a>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  )
}