import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './pages/auth/Login';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { Layout } from './layouts/Layout';
import { ResetPassword } from './pages/auth/ResetPassword';
import { getAuthStatus } from './misc/utils';
import { ExpiredSession } from './pages/auth/ExpiredSession';

// Wrapper to protect auth pages (e.g., Login) and redirect authenticated users
const AuthRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = getAuthStatus();

  return isAuthenticated ? <Navigate to="/orders" replace /> : <Element {...rest} />;
};

// Wrapper to protect private routes (e.g., Dashboard)
const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = getAuthStatus();

  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" replace />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Auth Pages */}
        <Route path="/" element={<AuthRoute element={Login} />} />
        <Route path="/login" element={<AuthRoute element={Login} />} />
        <Route path="/forgot-password" element={<AuthRoute element={ForgotPassword} />} />
        <Route path="/reset-password/:token" element={<AuthRoute element={ResetPassword} />} />
        
        <Route path="/expired-session" element={<ExpiredSession/>}  />

        {/* Protected Pages */}
        <Route path="/*" element={<PrivateRoute element={Layout} />} />
        
      </Routes>
    </Router>
  );
};

export default App;
