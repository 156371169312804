import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { getAxiosHeaders } from "../../misc/utils";

const CategorySelect = ({ field, onChange }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await axios.get(baseURLs.API_URL + "/products/category", {
          headers: getAxiosHeaders().headers
        }); 
        
        let responseInfo = response.data;
        if (response.status === 200) {
          setCategories(responseInfo.data.category);
        }
      } catch (err) {
        setError("Failed to load categories");
      } finally {
        setLoading(false);
      }
    };
    
    fetchCategories();
  }, []);

  useEffect(() => {
    onChange(inputValue); // Update form state when inputValue changes
  }, [inputValue, onChange]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setFilteredCategories(
      categories.filter((category) =>
        category.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleCategorySelect = (value) => {
    setInputValue(value);
    setFilteredCategories([]); // Reset list after selection
  };

  return (
    <div className="relative">
      <input
        {...field}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type to search or select category"
        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {loading && <div className="text-sm text-gray-500 mt-1">Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {filteredCategories.length > 0 && inputValue && (
        <ul className="absolute z-10 w-full bg-white border rounded-md max-h-40 overflow-y-auto mt-1 shadow-lg">
          {filteredCategories.map((category) => (
            <li
              key={category}
              onClick={() => handleCategorySelect(category)}
              className="px-4 py-2 cursor-pointer hover:bg-blue-100"
            >
              {category}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CategorySelect;
