import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SideNavBar } from './SideNav';
import { Header } from './Header';
import { Dashboard } from '../pages/admin/Dashboard';
import { Products } from '../pages/admin/Products';
import Head from './Head';
import { Unauthorized } from '../pages/admin/Unauthorized';
import { Orders } from '../pages/admin/Orders';
import { NotFound } from '../pages/auth/NotFound404';
import { AddCustomOrder } from '../pages/admin/AddOrder';
import { OrderDetails } from '../pages/admin/OrderDetials';
import { AddProduct } from '../pages/admin/AddProduct';
import { ImportProducts } from '../pages/admin/ImportProducts';
import { EditProduct } from '../pages/admin/EditProduct';
import { ProductDetails } from '../pages/admin/ProductDetails';

export const Layout = () => {
  return (
    <React.Fragment>
      <Head title="loading" />
        <Header />
        <SideNavBar className="fixed top-0 left-0 h-full w-[220px] lg:w-[280px]" />
        
        {/* Content Area */}
        <div
          className="flex flex-col lg:ml-[280px] mt-14 lg:mt-[60px] p-4">
          <Routes>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/add" element={<AddCustomOrder />} />
            <Route path="/orders/details/:orderID" element={<OrderDetails />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/add" element={<AddProduct />} />
            <Route path="/products/edit/:productID" element={<EditProduct />} />
            <Route path="/products/details/:productID" element={<ProductDetails />} />
            <Route path="/products/import" element={<ImportProducts />} />
            {/* <Route path="/deals" element={<Deals />} />
            <Route path="/team" element={<Team />} /> */}
            {/* Other routes */}
          </Routes>
        </div>
    </React.Fragment>
  );
};
