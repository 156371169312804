import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/pakkmax_logo.png";
import { Button } from "../../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { setCookie } from "../../misc/utils";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { AlertCircle } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";

export const ForgotPassword = () => {
  const [requesting, setRequesting] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [formError, setFormError] = useState("");
  
  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { handleSubmit, control, formState: { errors } } = methods;

  const onSubmit = (data) => {
    setRequesting(true);

    axios.post(baseURLs.API_URL + "/auth/request-password-reset", {
      email: data.email
    })
		.then((response) => {
      setRequesting(false);
      setEmailSent(true);

		}).catch((error) => {
      let errorResponse = error.response?.data ?? '';

      let errorMessage = 'Error: Could not connect to server';
      if(errorResponse.hasOwnProperty("error")){
        errorMessage = errorResponse.error;
      }

      setRequesting(false);
      setFormError(errorMessage);
		});
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {
        emailSent ?
        <Card className="w-full max-w-sm">
          <CardHeader className="text-center items-center mb-5">
            <img src={Logo} alt="Logo" width={150} className="mb-4" />
            <CardTitle className="text-2xl">Check your Email</CardTitle>
            <CardDescription>
              An email has been sent to {methods.getValues("email")} instructions to reset your password.
            </CardDescription>
          </CardHeader>
        </Card>
        :
        <Card className="w-full max-w-sm">
          <CardHeader className="text-center items-center mb-5">
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <img src={Logo} alt="Logo" width={150} className="mb-4" />
            </Link>
            <CardTitle className="text-2xl">Forgot Password</CardTitle>
            <CardDescription>
              If you forgot your password, don't worry, we’ll email you instructions to reset your password.
            </CardDescription>
          </CardHeader>
          
          {
            formError &&
            <div className="px-6 pb-6">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  {formError}
                </AlertDescription>
              </Alert>
            </div>
          }

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent className="grid gap-4">
                {/* Email Field */}
                <FormField
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          id="email"
                          type="email"
                          placeholder="m@example.com"
                          required
                        />
                      </FormControl>
                      {errors.email && (
                        <FormMessage>{errors.email.message}</FormMessage>
                      )}
                    </FormItem>
                  )}
                />
              </CardContent>
              <CardFooter className="block">
                <Button type="submit" className="w-full mb-4" disabled={requesting}>
                  {requesting ? "Submitting..." : "Submit"}
                </Button>
                <Link to={`${process.env.PUBLIC_URL}/login`}><Button variant="outline" className="w-full" type="button">Back to Login</Button></Link>
              </CardFooter>
            </form>
          </FormProvider>
        </Card>
      }
    </div>
  )
}
