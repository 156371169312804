import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import { Button, GoBackButton } from "../../components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import Head from "../../layouts/Head";
import { DownloadCloud, Terminal } from "lucide-react";
import { baseURLs } from "../../misc/constants";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Alert, AlertTitle } from "../../components/ui/alert";
import axios from "axios";
import { getAxiosUploadHeaders } from "../../misc/utils";

export const ImportProducts = () => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [showImportErrors, setShowImportErrors] = useState(false);
  const [importInfo, setImportInfo] = useState({});
  const [importErrors, setImportErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const methods = useForm({
    defaultValues: {
      file: null,
    },
  });
  const { handleSubmit, control, formState: { errors } } = methods;

  const [uploadedFile, setUploadedFile] = useState(null);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onSubmit = (data) => {
    if (!data.file || data.file.length === 0) {
      setErrorMessage("Please upload a CSV file");
      return;
    }
  
    const file = data.file[0]; // Get the first (and only) file from the FileList
  
    setRequesting(true);
    setErrorMessage("");
    const formData = new FormData();
    formData.append('products_csv', file);

    axios.post(baseURLs.API_URL + "/products/import", formData, getAxiosUploadHeaders())
    .then((response) => {
      let responseInfo = response.data;

      setSuccessMessage(`Total records processed: ${responseInfo.data.total_records}`);
      setImportInfo(responseInfo.data);
      setImportErrors(responseInfo.data.errors);
      toggleModal("successModal");
      setRequesting(false);
    }).catch((error) => {
      try{
        console.log(error);
        let errorResponse = error.response.data;
        
        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setRequesting(false);

        window.scrollTo({top: 0, behavior: 'smooth'});

      }catch(e){
        console.log(e)
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Import Products" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Import Products</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/products`} />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
              <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
              <Card>
                  <CardContent className="pt-6">
                    <Button type="button" variant="outline" className="w-full" onClick={() => window.open(baseURLs.PRODUCT_IMPORT_TEMPLATE_LINK, '_blank').focus()}>
                      <DownloadCloud className="mr-2" /> Download CSV Template
                    </Button>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="pb-3">
                    <CardTitle className="text-lg content-center">Upload CSV File</CardTitle>
                  </CardHeader>

                  <CardContent>
                    <div className="grid gap-4">
                      {/* CSV File Upload Field */}
                      <FormField
                        control={control}
                        name="file"
                        rules={{ required: "CSV file is required" }}
                        render={({ field: { onChange, value, ...rest } }) => ( // Destructure value and don't pass it to Input
                          <FormItem>
                            <FormLabel>CSV File</FormLabel>
                            <FormControl>
                              <Input
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                  onChange(e.target.files); // Update react-hook-form
                                  setUploadedFile(e.target.files[0]); // Update local state if needed
                                }}
                                {...rest} // Spread the rest of the props, excluding value
                              />
                            </FormControl>
                            {errors.file && (
                              <FormMessage>
                                {errors.file.message}
                              </FormMessage>
                            )}
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="mt-4">
                      <Button className="w-full" type="submit">
                        {requesting ? "Uploading..." : "Upload"}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </form>
        </FormProvider>
        {
          activeModal === 'successModal' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogHeader>
              <DialogTitle>Upload Completed</DialogTitle>
              <DialogDescription>{successMessage}</DialogDescription>
            </DialogHeader>
            <DialogContent>
              <Alert>
                <Terminal className="h-4 w-4" />
                <AlertTitle>Products Added: <b>{importInfo.added_products}</b></AlertTitle>
              </Alert>
              <Alert>
                <Terminal className="h-4 w-4" />
                <AlertTitle>Updated Products: <b>{importInfo.updated_products}</b></AlertTitle>
              </Alert>
              <Alert variant="destructive">
                <Terminal className="h-4 w-4" />
                <AlertTitle>Failed: <b>{importInfo.failed}.</b> <span className="alert-link pointer-cursor" onClick={() => setShowImportErrors(!showImportErrors)}>Click to {showImportErrors ? `hide` : `view`} {importInfo.failed > 1 ? `errors` : `error`}</span></AlertTitle>
              </Alert>

              {
                showImportErrors &&
                <div className="mt-4 text-start">
                  {
                    importErrors.map((error, index) => {
                      return (
                        <Alert variant="destructive" key={index}>
                          <AlertTitle>
                            <span className="d-block">Product: {error.product_name}</span>
                            {
                              error.errors.map((msg, msgIndex) => {
                                return (
                                  <span className="d-block" key={msgIndex}>{msgIndex + 1}. {msg}</span>
                                )
                              })
                            }
                          </AlertTitle>                          
                        </Alert>
                      )
                    })
                  }
                </div>
              }
            </DialogContent>
          </Dialog>
        }
      </main>
    </React.Fragment>
  );
};
