import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Bell,
  Pencil,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { getAxiosHeaders, ImageModal } from "../../misc/utils";
import { baseURLs } from "../../misc/constants";
import axios from "axios";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import { Separator } from "../../components/ui/separator";

export const ProductDetails = () => {
  const { productID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [productInfo, setProductInfo] = useState({});
  const [activeModal, setActiveModal] = useState(null);
  const [currentImage, setCurrentImage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    toggleModal('showImage');
  };

  const getProductInfo = () => {
    axios.get(`${baseURLs.API_URL}/products/${productID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setProductInfo(responseInfo.data.product_info);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    if(!productID){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getProductInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Product Details" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Product Details</h1>
          <div className="flex gap-x-2">
            <Link to={`${process.env.PUBLIC_URL}/products/edit/${productID}`}>
                <Button><Pencil className="mr-2" /> Edit Product</Button>
            </Link>
            <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/products`} />
          </div>
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="md:col-span-5">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
            <div className="md:col-span-7">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
          </div>
          :
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="md:col-span-5">
              <Card>
                <CardHeader>
                  <CardTitle className="text-md">Product Info</CardTitle>
                </CardHeader>
                <CardContent className="gap-8">
                  <div className="mb-3">
                    <p className="text-sm font-medium text-gray-500">Name:</p>
                    <p className="text-md font-semibold text-gray-800">{productInfo.product_name}</p>
                  </div>
                  <div className="mb-3">
                    <span className="text-sm font-medium text-gray-500">Description:</span>
                    <p className="text-md font-semibold text-gray-800">{productInfo.description}</p>
                  </div>
                  <hr className="mb-3" />
                  <div className="flex space-x-4 mb-5">
                    <div>
                      <span className="text-sm font-medium text-gray-500">Unit Cost:</span>
                      <p className="text-md font-semibold text-gray-800">¥ {productInfo.unit_cost}</p>
                    </div>

                    <div>
                      <span className="text-sm font-medium text-gray-500">MOQ:</span>
                      <p className="text-md font-semibold text-gray-800">{productInfo.moq}<small className="pl-1">{productInfo.unit}</small></p>
                    </div>

                    <div>
                      <span className="text-sm font-medium text-gray-500">Category:</span>
                      <div className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-full text-sm">
                        <span>{productInfo.category}</span>
                      </div>
                    </div>
                    
                  </div>
                  <hr className="mb-3" />
                  <div className="mb-3">
                    <span className="text-sm font-medium text-gray-500">Tags:</span>

                    <div className="flex flex-wrap gap-2 mt-2">
                      {productInfo.tags.map((tag, index) => (
                        <div key={index} className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-full text-sm">
                          <span>{tag}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="mb-3" />
                  <div className="mb-3">
                    <span className="text-sm font-medium text-gray-500">Supplier Name:</span>
                    <p className="text-md font-semibold text-gray-800">{productInfo.supplier_name}</p>
                  </div>
                  <div className="mb-3">
                    <span className="text-sm font-medium text-gray-500">Platform Name:</span>
                    <p className="text-md font-semibold text-gray-800">{productInfo.platform_name}</p>
                  </div>
                  <hr className="mb-3" />
                  <div className="pt-3">
                    <a href={productInfo.product_url} target="_blank"><Button variant="outline" className="w-full">View Product</Button></a>
                  </div>
                </CardContent>
              </Card>
              
            </div>
            <div className="md:col-span-7 grid-cols-1 gap-8">
              {
                productInfo.images.length > 0 &&
                <Card className="mb-5">
                  <CardHeader>
                    <CardTitle className="text-md">Product Images</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-wrap gap-4">
                      {/* Image thumbnails */}
                      {
                        productInfo.images.map((image, index) => {
                          return (
                            <img src={image} key={index}
                              className="cursor-pointer w-24 h-24 object-cover rounded-md border border-gray-200"
                              onClick={() => openModal(image)}
                            />
                          )
                        })
                      }                      
                    </div>
                  </CardContent>
                </Card>
              }

              {
                productInfo.variants.length > 0 &&
                <Card className="mt-5">
                  <CardHeader>
                    <CardTitle className="text-md">Variants</CardTitle>
                  </CardHeader>
                  <CardContent>
                    {
                      productInfo.variants.map((variant, index) => {
                        return (
                          <React.Fragment>
                            {index > 0 && <hr className="mb-3" />}
                            <div className="mb-3 flex justify-between">
                              <p className="text-md font-semibold text-gray-800">{variant.variant_name}</p>
                              <span className="text-sm font-medium text-gray-500">¥ {variant.unit_cost} ~ GHS {variant.ghs_cost}</span>
                            </div>
                          </React.Fragment>
                        )
                      })
                    }                      
                  </CardContent>
                </Card>
              }

              {
                activeModal === "showImage" && 
                <ImageModal
                  open={true}
                  onClose={() => toggleModal(null)}
                  imageUrl={currentImage}
                />
              }
            </div>
          </div>
        }
      </main>
    </React.Fragment>
  )
}
