import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/pakkmax_logo.png";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { setCookie } from "../../misc/utils";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { AlertCircle } from "lucide-react";

export const Login = () => {
  const [requesting, setRequesting] = useState(false);
  const [formError, setFormError] = useState("");
  
  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { handleSubmit, control, formState: { errors } } = methods;

  const onSubmit = (data) => {
    setRequesting(true);

    axios.post(baseURLs.API_URL + "/auth/login", {
      email: data.email,
      password: data.password
    })
		.then((response) => {
      let responseInfo = response.data;
      
      let expirationDate = new Date();
      let validDays = 30;
      expirationDate.setTime(expirationDate.getTime() + (validDays*24*60*60*1000));

      setCookie('access_token', responseInfo.data.access_token, expirationDate)
      localStorage.setItem('admin_info', JSON.stringify(responseInfo.data));
      window.location = `${process.env.PUBLIC_URL}/orders`;

		}).catch((error) => {
      setRequesting(false);
      setFormError("Invalid login credentials");
		});
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-sm">
        <CardHeader className="text-center items-center mb-5">
          <Link to={`${process.env.PUBLIC_URL}/login`}>
            <img src={Logo} alt="Logo" width={150} className="mb-4" />
          </Link>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account.
          </CardDescription>
        </CardHeader>

        {
          formError &&
          <div className="px-6 pb-6">
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                {formError}
              </AlertDescription>
            </Alert>
          </div>
        }
        
        {/* FormProvider wraps the form to pass down the context */}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent className="grid gap-4">
              {/* Email Field */}
              <FormField
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Please enter a valid email address",
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        id="email"
                        type="email"
                        placeholder="m@example.com"
                        required
                      />
                    </FormControl>
                    {errors.email && (
                      <FormMessage>{errors.email.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />

              {/* Password Field */}
              <FormField
                name="password"
                control={control}
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center">
                      <FormLabel>Password</FormLabel>
                      <Link
                        to={`${process.env.PUBLIC_URL}/forgot-password`}
                        className="ml-auto inline-block text-sm underline"
                      >
                        Forgot your password?
                      </Link>
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        id="password"
                        type="password"
                        required
                      />
                    </FormControl>
                    {errors.password && (
                      <FormMessage>{errors.password.message}</FormMessage>
                    )}
                  </FormItem>
                )}
              />
            </CardContent>

            {/* Submit Button */}
            <CardFooter>
              <Button type="submit" className="w-full" disabled={requesting}>
                {requesting ? "Logging in..." : "Login"}
              </Button>
            </CardFooter>
          </form>
        </FormProvider>
      </Card>
    </div>
  );
};
