import { cn } from "../../lib/utils"
import { Card, CardContent, CardHeader, CardTitle } from "./card";

function Skeleton({ className, ...props }) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted", className)}
      {...props}
    />
  )
}

const FormSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Loading Form...</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {/* Skeleton for each form field */}
          <div className="space-y-2">
            <Skeleton className="h-4 w-1/4" /> {/* Simulates the label */}
            <Skeleton className="h-10 w-full rounded-md" /> {/* Simulates the input field */}
          </div>
          <div className="space-y-2">
            <Skeleton className="h-4 w-1/4" />
            <Skeleton className="h-10 w-full rounded-md" />
          </div>
          <div className="space-y-2">
            <Skeleton className="h-4 w-1/4" />
            <Skeleton className="h-10 w-full rounded-md" />
          </div>
          {/* Skeleton for the button */}
          <Skeleton className="h-12 w-1/2 rounded-md mt-6" />
        </div>
      </CardContent>
    </Card>
  );
}

const TableSkeleton = () => {
  const rows = 6; // number of rows to display in skeleton
  const columns = 4; // number of columns to display in skeleton

  return (
    <div className="overflow-x-auto w-full">
      <table className="w-full table-auto">
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, index) => (
              <th key={index} className="px-4 py-2">
                <Skeleton className="h-6 w-24" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex} className="border-t">
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} className="px-4 py-3">
                  <Skeleton className="h-6 w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { Skeleton, FormSkeleton, TableSkeleton }
