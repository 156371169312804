import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  CircleX,
  Cross,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { useDropzone } from 'react-dropzone';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { getAxiosUploadHeaders } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";

export const AddCustomOrder = () => {
  const navigate = useNavigate();
  const [requesting, setRequesting] = useState(false);
  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imagesError, setImagesError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      customer_name: "",
      phone_number: "",
      note: ""
    },
  });

  const { handleSubmit, control, formState: { errors }, setError } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');
    setImagesError('');
  
    const formData = new FormData();
  
    files.forEach(file => {
      formData.append('product_images', file); // Append files to formData
    });
  
    formData.set("customer_name", data.customer_name);
    formData.set("phone_number", data.phone_number);
    formData.set("note", data.note);
  
    axios.post(baseURLs.API_URL + "/orders/custom", formData, getAxiosUploadHeaders())
      .then((response) => {
        let responseInfo = response.data;
        let orderID = responseInfo.data.order_id;
  
        navigate(`${process.env.PUBLIC_URL}/orders/details/${orderID}`);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors.customer_name) {
            setError('customer_name', {
              type: 'server',
              message: responseErrors.customer_name
            });
          }
  
          if (responseErrors.phone_number) {
            setError('phone_number', {
              type: 'server',
              message: responseErrors.phone_number
            });
          }
  
          if (responseErrors.note) {
            setError('note', {
              type: 'server',
              message: responseErrors.note
            });
          }
  
          if (responseErrors.product_images) {
            setImagesError(responseErrors.product_images);
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };  

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  }, []);

  const removeFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileToRemove.name));
    URL.revokeObjectURL(fileToRemove.preview); // Clean up the preview URL
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  useEffect(() => {
    // Clean up the URL objects to avoid memory leaks
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <React.Fragment>
      <Head title="Add Order" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Create Custom Order</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/orders`} />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
              <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
                {
                  errorMessage &&
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {errorMessage}
                    </AlertDescription>
                  </Alert>
                }
                <Card>
                  <CardHeader className="pb-3"> 
                    <CardTitle className="text-lg">Customer Info</CardTitle>
                  </CardHeader>
                  <CardContent className="grid gap-6">
                    {/* Customer Field */}
                    <FormField
                      name="customer_name"
                      control={control}
                      rules={{
                        required: "Customer name is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Customer Name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              id="customer_name"
                              type="text"
                              placeholder="eg. Nana Kojo"
                              required
                            />
                          </FormControl>
                          {errors.phone_number && (
                            <FormMessage>{errors.phone_number.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                    {/* Phone number Field */}
                    <FormField
                      name="phone_number"
                      control={control}
                      rules={{
                        required: "Phone number is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Phone Number</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              id="phone_number"
                              type="text"
                              placeholder="eg. 0201234567"
                              required
                            />
                          </FormControl>
                          {errors.phone_number && (
                            <FormMessage>{errors.phone_number.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="pb-3"> 
                    <CardTitle className="text-lg">Order Info</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <FormLabel>Upload Product Images</FormLabel>
                    <div
                      {...getRootProps()}
                      className={`mt-3 border-2 border-dashed p-6 rounded-md 
                      ${isDragActive ? 'border-blue-500 bg-blue-100' : 'border-gray-300'}`}
                    >
                      <input {...getInputProps()} />
                      <div className="text-center">
                        {isDragActive ? (
                          <p className="text-blue-500">Drop the files here...</p>
                        ) : (
                          <p className="text-gray-500">Drag & drop some files here, or click to select files</p>
                        )}
                      </div>
                    </div>

                    {imagesError && (
                      <FormMessage>{imagesError}</FormMessage>
                    )}

                    {files.length > 0 && (
                      <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 gap-4">
                        {files.map((file, index) => (
                          <div key={index} className="relative group">
                            <div className="w-full h-40 bg-gray-200 border-2 rounded-md overflow-hidden">
                              <img
                                src={file.preview}
                                alt={file.name}
                                className="object-cover w-full h-full"
                              />
                            </div>
                            {/* Remove button */}
                            <button
                              onClick={() => removeFile(file)}
                              className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                            >
                              <CircleX/>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Note Field */}
                    <div className="mt-5">
                      <FormField
                        name="note"
                        control={control}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Note</FormLabel>
                            <FormControl>
                              <Textarea
                                {...field}
                                id="note"
                                placeholder="eg. I will like..."
                              />
                            </FormControl>
                            {errors.note && (
                              <FormMessage>{errors.note.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <Button type="submit" className="w-full" disabled={requesting}>
                      {requesting ? "Submitting..." : "Submit"}
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </form>
        </FormProvider>
      </main>
    </React.Fragment>
  )
}
