import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/pakkmax_logo.png";
import { Button } from "../../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import Head from "../../layouts/Head";
import { deleteCookie } from "../../misc/utils";

export const ExpiredSession = () => {
  const [countdown, setCountdown] = useState(3); // 3-second countdown

  const handleLogout = () => {
    deleteCookie("access_token");
    localStorage.clear();
    window.location = `${process.env.PUBLIC_URL}/login`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // Redirect after countdown finishes
    if (countdown === 0) {
      handleLogout();
    } else {
      // Cleanup the timer
      return () => clearInterval(timer);
    }

    
  }, [countdown]);

  return (
    <React.Fragment>
      <Head title="Expired Session" />
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <Card className="w-full max-w-sm">
          <CardHeader className="text-center items-center mb-5">
            <img src={Logo} alt="Logo" width={150} className="mb-4" />
            <CardTitle className="text-2xl">Expired Session</CardTitle>
            <CardDescription>
              Your session has expired, you will be redirected to login in: {countdown} {countdown > 1 ? `seconds`: `second`}
            </CardDescription>
          </CardHeader>
          
          <CardFooter className="block">
            <Button className="w-full" onClick={handleLogout}>Back to Login</Button>
          </CardFooter>
        </Card>
      </div>
    </React.Fragment>
  )
}
