import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../assets/images/pakkmax_logo.png";
import { Button } from "../../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { AlertCircle } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";

export const ResetPassword = ({ match }) => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [invalidToken, setInvalidToken] = useState(true);
  const [fullName, setFullName] = useState("");
  const [formError, setFormError] = useState("");
  
  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      password: "",
    },
  });

  const { handleSubmit, control, formState: { errors } } = methods;

  const onSubmit = (data) => {
    setRequesting(true);

    axios.post(baseURLs.API_URL + "/auth/reset-password", {
      password: data.password,
      token,
    })
		.then((response) => {
      setRequesting(false);
      setPasswordUpdated(true);

		}).catch((error) => {
      let errorResponse = error.response?.data ?? '';

      let errorMessage = 'Error: Could not connect to server';
      if(errorResponse.hasOwnProperty("error")){
        errorMessage = errorResponse.error;
      }

      setRequesting(false);
      setFormError(errorMessage);
		});
  };

  const validateResetPasswordToken = (token) => {
    axios.get(baseURLs.API_URL + `/auth/verify-reset-password-token/${token}`)
    .then((response) => {
      let responseInfo = response.data;
      setFullName(responseInfo.data.full_name);
      setInvalidToken(false);
      setLoading(false);
    }).catch((error) => {
      setFormError("Invalid password reset token");
      setLoading(false);
    });
  }

  useEffect(() => {
    if (!token) {
      setFormError("Invalid password reset token");
      setInvalidToken(true);
      setLoading(false);
    } else {
      validateResetPasswordToken(token);
    }
  }, [token]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {
        invalidToken &&
        <Card className="w-full max-w-sm">
          <CardHeader className="text-center items-center mb-5">
            <img src={Logo} alt="Logo" width={150} className="mb-4" />
            <CardTitle className="text-2xl">Reset password</CardTitle>
            <CardDescription>
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  {formError}
                </AlertDescription>
              </Alert>
            </CardDescription>
          </CardHeader>
        </Card>
      }
      {
        passwordUpdated && !invalidToken &&
        <Card className="w-full max-w-sm">
          <CardHeader className="text-center items-center mb-5">
            <img src={Logo} alt="Logo" width={150} className="mb-4" />
            <CardTitle className="text-2xl">Password Changed</CardTitle>
            <CardDescription>
            You have successfully changed your account password.
            </CardDescription>
          </CardHeader>
          
          <CardFooter className="block">
            <Link to={`${process.env.PUBLIC_URL}/login`}><Button className="w-full" type="button">Back to Login</Button></Link>
          </CardFooter>
        </Card>
      }

      {
        loading ?
        <Card className="w-full max-w-sm">
          <CardHeader className="text-center items-center mb-5">
            <img src={Logo} alt="Logo" width={150} className="mb-4" />
            <CardTitle className="text-2xl">Password Changed</CardTitle>
            <CardDescription>
            You have successfully changed your account password.
            </CardDescription>
          </CardHeader>
          
          <CardFooter className="block">
            <Button type="submit" className="w-full mb-4" disabled={loading}>
              {requesting ? "Submitting..." : "Submit"}
            </Button>
            <Link to={`${process.env.PUBLIC_URL}/login`}><Button variant="outline" className="w-full" type="button">Back to Login</Button></Link>
          </CardFooter>
        </Card>
        :
        <>
          {
            !passwordUpdated && !invalidToken &&
            <Card className="w-full max-w-sm">
              <CardHeader className="text-center items-center mb-5">
                <Link to={`${process.env.PUBLIC_URL}/login`}>
                  <img src={Logo} alt="Logo" width={150} className="mb-4" />
                </Link>
                <CardTitle className="text-2xl">Reset Password</CardTitle>
                <CardDescription>
                  Hi <b>{fullName}</b>, set a new password for your account.
                </CardDescription>
              </CardHeader>
              
              {
                formError &&
                <div className="px-6 pb-6">
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {formError}
                    </AlertDescription>
                  </Alert>
                </div>
              }

              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent className="grid gap-4">
                    {/* Password Field */}
                    <FormField
                      name="password"
                      control={control}
                      rules={{ required: "Password is required" }}
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex items-center">
                            <FormLabel>New Password</FormLabel>
                          </div>
                          <FormControl>
                            <Input
                              {...field}
                              id="password"
                              type="password"
                              required
                            />
                          </FormControl>
                          {errors.password && (
                            <FormMessage>{errors.password.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />
                  </CardContent>
                  <CardFooter className="block">
                    <Button type="submit" className="w-full mb-4" disabled={loading}>
                      {requesting ? "Submitting..." : "Submit"}
                    </Button>
                  </CardFooter>
                </form>
              </FormProvider>
            </Card>
          }
        </>
      }
    </div>
  )
}
