import React from "react";
import Logo from "../assets/images/pakkmax_logo.png";
import { Link } from "react-router-dom";
import classNames from "classnames";

const PAKKmaxLogo = ({ className, size, ...props }) => {
  const logoClass = classNames({
    "w-32 logo-img": true,
    [`${className}`]: className,
  });

  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className={logoClass} src={Logo} alt="Logo" />
    </Link>
  );
};

export default PAKKmaxLogo;
