import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { useForm, Controller } from "react-hook-form"; // Use Controller
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label"; // Import Label component
import { AlertCircle, X } from "lucide-react";
import { generateVariants } from "../misc/utils";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";

export const AddVariantTypeDialog = ({ isOpen, onClose, variantTypes, setVariantTypes, variants, setMainFormValue, setVariants, productName, unitCost }) => {
  // Initialize the form methods
  const { control, handleSubmit, reset, formState: { errors } } = useForm();
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Handle adding options as pills
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      if (currentOption.trim() !== "") {
        setOptions([...options, currentOption.trim()]);
        setCurrentOption("");
      }
    }
  };

  // Handle removing a pill
  const handleRemoveOption = (optionToRemove) => {
    setOptions(options.filter(option => option !== optionToRemove));
  };

  // Handle form submission with validation
  const onSubmit = (data) => {
    if (options.length === 0) {
      alert("Please add at least one option.");
      return;
    }

    let _variantTypes = variantTypes;
    let _variantTypeName = (data.variant_type_name).toLowerCase();
    let typeExist = _variantTypes.some(variant => variant.type === _variantTypeName);

    if (typeExist) {
      setErrorMessage(`${_variantTypeName} already exists.`);
    } else {
      _variantTypes.push({
        type: _variantTypeName,
        options: options
      })

      let productVariants = generateVariants(variantTypes, variants, productName, unitCost);    

      setMainFormValue("variants", productVariants);
      setVariants([...productVariants]);
      setVariantTypes([..._variantTypes]);
  
      onClose(); // Close dialog on submit
      reset(); // Reset the form after submit
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="mb-4">
          <DialogTitle>Add Variant Type</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            {/* Label and Input for Variant Type Name */}
            <div className="grid gap-2">
              <Label htmlFor="variant_type_name">Variant Type Name</Label>
              <Controller
                name="variant_type_name"
                control={control}
                defaultValue=""
                rules={{ required: "Variant type name is required." }}
                render={({ field }) => (
                  <div>
                    <Input
                      id="variant_type_name"
                      type="text"
                      placeholder="Enter variant type name"
                      {...field}
                    />
                    {errors.variant_type_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.variant_type_name.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            {/* Label and Input for Options */}
            <div className="grid gap-2">
              <Label htmlFor="variant_options">Options</Label>
              <Input
                id="variant_options"
                type="text"
                placeholder="Enter option and press Enter or ,"
                value={currentOption}
                onChange={(e) => setCurrentOption(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {options.length === 0 && (
                <p className="text-red-500 text-sm mt-1">
                  Please add at least one option.
                </p>
              )}
              <div className="flex flex-wrap gap-2 mt-2">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-full text-sm"
                  >
                    <span>{option}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveOption(option)}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Dialog Footer */}
          <DialogFooter>
            <Button type="submit">Add Variant Type</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const EditVariantTypeDialog = ({ isOpen, onClose, editVariantTypeIndex, variantTypes, setVariantTypes, variants, setMainFormValue, setVariants, productName, unitCost }) => {
  // Initialize the form methods
  const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Handle adding options as pills
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      if (currentOption.trim() !== "") {
        setOptions([...options, currentOption.trim()]);
        setCurrentOption("");
      }
    }
  };

  // Handle removing a pill
  const handleRemoveOption = (optionToRemove) => {
    setOptions(options.filter(option => option !== optionToRemove));
  };

  // Handle form submission with validation
  const onSubmit = (data) => {
    if (options.length === 0) {
      alert("Please add at least one option.");
      return;
    }

    let _variantTypes = variantTypes;
    let _variantTypeName = (data.variant_type_name).toLowerCase();
    let typeExist = _variantTypes.some((variant, index) => variant.type === _variantTypeName && index !== editVariantTypeIndex);

    if (typeExist) {
      setErrorMessage(`${_variantTypeName} already exists.`);
    } else {
      _variantTypes[editVariantTypeIndex] = {
        type: _variantTypeName,
        options: options
      }

      let productVariants = generateVariants(variantTypes, variants, productName, unitCost);    

      setMainFormValue("variants", productVariants);
      setVariants([...productVariants]);
      setVariantTypes([..._variantTypes]);
  
      onClose(); // Close dialog on submit
    }
  };

  useEffect(() => {
    let _variantType = variantTypes[editVariantTypeIndex];
    setValue('variant_type_name', _variantType.type)
    setOptions([..._variantType.options]);

  }, [])

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="mb-4">
          <DialogTitle>Add Variant Type</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            {/* Label and Input for Variant Type Name */}
            <div className="grid gap-2">
              <Label htmlFor="variant_type_name">Variant Type Name</Label>
              <Controller
                name="variant_type_name"
                control={control}
                defaultValue=""
                rules={{ required: "Variant type name is required." }}
                render={({ field }) => (
                  <div>
                    <Input
                      id="variant_type_name"
                      type="text"
                      placeholder="Enter variant type name"
                      {...field}
                    />
                    {errors.variant_type_name && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.variant_type_name.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            {/* Label and Input for Options */}
            <div className="grid gap-2">
              <Label htmlFor="variant_options">Options</Label>
              <Input
                id="variant_options"
                type="text"
                placeholder="Enter option and press Enter or ,"
                value={currentOption}
                onChange={(e) => setCurrentOption(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {options.length === 0 && (
                <p className="text-red-500 text-sm mt-1">
                  Please add at least one option.
                </p>
              )}
              <div className="flex flex-wrap gap-2 mt-2">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-full text-sm"
                  >
                    <span>{option}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveOption(option)}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Dialog Footer */}
          <DialogFooter>
            <Button type="submit">Edit Variant Type</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
