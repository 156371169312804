import React, { useState, useEffect } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../components/ui/select";

export const UnitSelect = ({ field, onChange }) => {
  const [unit, setUnit] = useState("");

  const handleUnitChange = (value) => {
    setUnit(value);
    onChange(value); // Trigger form change
  };

  return (
    <Select {...field} onValueChange={handleUnitChange} value={unit}>
      <SelectTrigger>
        <SelectValue placeholder="Select unit" />
      </SelectTrigger>
      <SelectContent>
          <SelectItem value="pieces">Pieces</SelectItem>
          <SelectItem value="units">Units</SelectItem>
          <SelectItem value="items">Items</SelectItem>
          <SelectItem value="pairs">Pairs</SelectItem>
          <SelectItem value="liters">Liters</SelectItem>
          <SelectItem value="milliliters">Milliliters</SelectItem>
          <SelectItem value="gallons">Gallons</SelectItem>
          <SelectItem value="cubic-meters">Cubic Meters</SelectItem>
      </SelectContent>
    </Select>
  );
};
