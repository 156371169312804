export const baseURLs = {
  KUS_URL:  process.env.REACT_APP_MODE === 'production' ? 
    "https://pakkmax.com" : process.env.REACT_APP_MODE === 'uat' ? 
    "https://uat-website.pakkmax.com" : "http://localhost:7000",
  CLIENT_APP_URL:  process.env.REACT_APP_MODE === 'production' ? 
    "https://app.pakkmax.com" : process.env.REACT_APP_MODE === 'uat' ? 
    "https://uat-app.pakkmax.com" : "http://localhost:3000",
  API_URL:  process.env.REACT_APP_MODE === 'production' ? 
    "https://api.pakkmax.com" : process.env.REACT_APP_MODE === 'uat' ? 
    "https://uat-api.pakkmax.com" : "http://localhost:5070",
  PRODUCT_IMPORT_TEMPLATE_LINK: "https://pm-bulk-upload.s3.eu-north-1.amazonaws.com/bulk_product_upload_pakkmax.csv",
}

export const fcmVAPID = "BPuETUScGPsTYBo-IPyF313feRXthLnxIZ3XKyltGA65-ZIGdr-BOy_ODKmiLX6qOYGZpLS1NdTmxB3lP0JEms8";

